<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      {{ $t(`${videoTitle}`) }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="video-container">
        <iframe
          :src="videoSource"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "HelpVideoFrame",
  props: ["videoTitle", "videoSource"],
};
</script>

<style>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
